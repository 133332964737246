export const Strings = {
	/* NavBar */
	navbar_title:                      "Camille & Adrien",

	/* Banner */
	banner_msg:                        "Notre dernier voyage",
	banner_btn:                        "LIRE L'ARTICLE",

	/* Cards */
	cards_header_title:                "Nos voyages",

	/* About Us */
	about_us_title:                    "A propos de nous",

	/* Promo */
	promo_title:                       "Codes Promo",
}
