export const Paths = {
	auvergne                  : "/auvergne",
	berlin                    : "/berlin",
	amsterdam                 : "/amsterdam",
	crete                     : "/crete",
	bourges                   : "/bourges",
	bourgogne                 : "/bourgogne",
	bretagne                  : "/bretagne",
	corse                     : "/corse",
	etretat                   : "/etretat",
	gacilly                   : "/gacilly",
	honfleur                  : "/honfleur",
	mans                      : "/mans",
	malta                     : "/malta",
	perche                    : "/perche",
	trouville                 : "/trouville",
	home                      : "/",
}
